<template>
  <div class="delivery-event">
    <div class="cdp-indicator" :style="indicatorCssColor" />
    <div class="delivery-event-heading flex f-jsb" :class="classes">
      <div class="flex pr-2 w-100">
        <div class="flex f-jsa f-ai f-column icon-panel">
          <b-icon
            v-if="loading.pickup === 'above'"
            icon="loading"
            class="pickup-loading"
            custom-class="mdi-spin"
          />
          <Tooltip
            v-else-if="allowAddPickup"
            :active="!loadingPickup"
            class="add-above"
            :label="$t('manifest.addPickupAbove')"
          >
            <ArrowPlusUp @click="onAddPickup('above')" :disabled="!!loadingPickup" />
          </Tooltip>
          <DeliveryStatusSignUI
            class="delivery-status-sign ml-3 mr-1"
            :delivery="delivery"
            markerShowNumbers
            @status="(data) => (status = data)"
            isMapMarker
            :isCollect="isCollect"
          />
          <b-icon
            v-if="loading.pickup === 'bellow'"
            icon="loading"
            class="pickup-loading"
            custom-class="mdi-spin"
          />
          <Tooltip
            v-else-if="allowAddPickup"
            :active="!loadingPickup"
            class="add-bellow"
            :label="$t('manifest.addPickupBellow')"
          >
            <ArrowPlusDown @click="onAddPickup('bellow')" :disabled="!!loadingPickup" />
          </Tooltip>
        </div>
        <div class="flex f-jc f-column pt-1 pb-1 w-100">
          <div class="flex f-jsb w-100 f-ai">
            <p>
              <span class="title-type">
                {{ status.participant_name || status.name }}
              </span>
              <strong v-if="isPickup"> - {{ delivery.close_door_pharmacy_name }} </strong>
            </p>
            <DeliveryIcons
              v-if="isDelivery"
              class="ml-2 icons-deliverys"
              isIconTag
              :status="{
                rush: delivery.rush,
                priority: this.delivery.priority,
                refrigerated: delivery.refrigeration_needed,
                leaveAtDoor: delivery.leave_at_door,
                collect: isCollect
              }"
            />
          </div>

          <div class="">
            <div v-if="isDelivery">
              <p class="title-cdp flex f-ai">
                <b-icon icon="arrow-up-bold-box" class="upDownBoxIcon" custom-class="icon-18" />
                {{ delivery.close_door_pharmacy_name }}
              </p>
              <p v-if="showAddress" class="title-address flex f-ai">
                <b-icon icon="arrow-down-bold-box" class="upDownBoxIcon" custom-class="icon-18" />
                {{ delivery.destination_full_address || '-' }}
              </p>

              <div class="flex f-jsb">
                <div class="copayment">
                  <CopaymentTag :value="delivery.copayment_amount" />
                </div>
                <div class="flex f-jsb">
                  <CopyTextToClipBoard
                    class="order-number"
                    v-if="isDelivery"
                    :copyToText="delivery.order_number"
                    :text="`#${delivery.order_number}`"
                  />
                  <div class="flex">
                    <Link
                      :disabled="disabled || completedState"
                      :label="$t('schedule.manifest.undoAssign')"
                      :loading="loading.undoAssign"
                      :permission="Permission.unassign"
                      @click="onUndoAssign"
                      class="dropdown options-item ml-1"
                      icon="clipboard-arrow-left"
                      v-if="isRemovable"
                    />
                    <Link
                      :icon="isOpen ? 'chevron-up' : 'chevron-down'"
                      :label="isOpen ? $t('global.lessDetails') : $t('global.moreDetails')"
                      @click="isOpen = !isOpen"
                      class="dropdown"
                    />
                  </div>
                </div>
              </div>
            </div>
            <EtaTiming
              v-if="!isDelivery && delivery.eta"
              class="mr-auto eta"
              :eta="delivery.eta"
              :useIcon="true"
              :offset="delivery.time_offset"
            />
          </div>
        </div>
        <div
          v-if="isPickup && !disabled"
          class="flex f-ai removePickup"
          :class="isRemovePickupLoading ? 'isLoading' : ''"
        >
          <Link
            :label="$t('manifest.removePickup')"
            :disabled="!!loadingRemovePickup"
            :loading="isRemovePickupLoading"
            @click="onRemovePickup"
            icon="delete-outline"
          />
        </div>
      </div>
    </div>
    <b-collapse class="delivery-event-collapse" animation="slide" v-model="isOpen">
      <div class="delivery-event-body pt-2">
        <div class="flex f-jsb">
          <div class="flex f-jsb">
            <DeliveryIcons
              v-if="isDelivery"
              isTag
              :status="{
                collect: isCollect,
                leaveAtDoor: delivery.leave_at_door,
                priority: delivery.priority,
                refrigerated: delivery.refrigeration_needed,
                rush: delivery.rush
              }"
            >
              <DeliveryStatusSignUI :delivery="delivery" class="mb-3" :isCollect="isCollect" />
            </DeliveryIcons>
          </div>
          <div class="flex f-jce is-align-items-center ml-auto">
            <Dropdown
              v-if="delivery.event_type_is_mandatory !== 1 && !disabled"
              :disabled="optionsDropdown.length === 0"
              :items="optionsDropdown"
              :label="$t('global.options')"
              :maxHeight="200"
              :showLabel="false"
              @click="(value) => emitDropdownEvent(value)"
              icon="dots-horizontal"
              position="is-bottom-left"
            />
          </div>
        </div>
        <div v-if="delivery.delivery_start_time || delivery.delivery_end_time">
          <div class="alert-advice">
            <b-icon icon="alert-circle-outline" class="icon" />
            <ConstraintDatesViewer
              v-if="delivery.delivery_start_time || delivery.delivery_end_time"
              :startTime="delivery.delivery_start_time"
              :endTime="delivery.delivery_end_time"
              :offset="delivery.time_offset"
            />
          </div>
        </div>
        <div v-if="[7, 11, 16, 17].includes(delivery.delivery_event_status_id)">
          <MessageBadge
            type="is-danger"
            :message="
              delivery.non_delivery_reason_id !== 4
                ? delivery.non_delivery_reason_description
                : delivery.other_non_delivery_reason_description
            "
          />
        </div>
        <EtaTiming
          v-if="delivery.eta"
          :eta="delivery.eta"
          :useIcon="true"
          :offset="delivery.time_offset"
        />
        <p v-if="delivery.estimated_distance">
          {{ $t('schedule.manifest.distance') }}: {{ delivery.estimated_distance || '-' }}
        </p>
        <p>
          {{ $t('address.address', [$tc('')]) }}: {{ delivery.destination_full_address || '-' }}
        </p>
        <p v-if="delivery.gate_code">
          {{ $t('global.gateCode') }}: {{ delivery.gate_code || '-' }}
        </p>
        <p v-if="delivery.comments">
          {{ $tc('global.comment', 2) }}: {{ delivery.comments || '-' }}
        </p>
        <div class="flex f-column mt-1">
          <div v-if="delivery.participant_phone_number" class="phone-info flex">
            <Tooltip
              :label="
                delivery.is_phone_number_delivery_event
                  ? $t('shcedule.phoneAssociatedToEvent')
                  : $t('global.defaultPhone')
              "
            >
              <b-icon
                :icon="delivery.is_phone_number_delivery_event ? 'check-decagram' : 'star'"
                :class="delivery.is_phone_number_delivery_event ? 'mobile-icon' : 'land-icon'"
              />
            </Tooltip>
            <p class="ml-2">
              {{ $tc('contact.landline') }}: {{ delivery.participant_phone_number }}
            </p>
          </div>
          <div v-if="delivery.participant_mobile_number" class="phone-info flex">
            <Tooltip
              :label="
                delivery.is_mobile_phone_number_delivery_event
                  ? $t('shcedule.phoneAssociatedToEvent')
                  : $t('global.defaultPhone')
              "
            >
              <b-icon
                :icon="delivery.is_mobile_phone_number_delivery_event ? 'check-decagram' : 'star'"
                :class="
                  delivery.is_mobile_phone_number_delivery_event ? 'mobile-icon' : 'land-icon'
                "
              />
            </Tooltip>
            <p class="ml-2">
              {{ $tc('contact.mobile') }}: {{ delivery.participant_mobile_number }}
            </p>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { Permissions } from '@/utils/Secure';
import { sortBy, prop, is } from 'ramda';
import {
  ConstraintDatesViewer,
  CopaymentTag,
  DeliveryIcons,
  DeliveryStatusSignUI,
  EtaTiming,
  MessageBadge,
  Tooltip,
  CopyTextToClipBoard,
  Link,
  Dropdown
} from '@/components';
import { ArrowPlusDown, ArrowPlusUp } from '../Icons';

export default {
  components: {
    ConstraintDatesViewer,
    CopaymentTag,
    DeliveryIcons,
    DeliveryStatusSignUI,
    EtaTiming,
    CopyTextToClipBoard,
    MessageBadge,
    Tooltip,
    Link,
    Dropdown,
    ArrowPlusUp,
    ArrowPlusDown
  },
  data() {
    return {
      fast: false,
      isOpen: false,
      late: false,
      loading: { undoAssign: false, pickup: '' },
      status: {}
    };
  },
  computed: {
    isRemovePickupLoading() {
      return this.loadingRemovePickup && this.loadingRemovePickup === this.delivery.id;
    },
    indicatorCssColor() {
      const color = this.delivery?.color;
      return color ? `background-color:${this.delivery.color};` : '';
    },
    allowAddPickup() {
      return !this.disabled && !this.isPickup;
    },
    priority() {
      const isString = is(String);

      if (!isString(this.delivery.priority)) return;
      return this.delivery.priority;
    },
    classes() {
      const classes = [];
      if (this.isOpen) classes.push('is-open');
      if (this.delivery.delivery_start_time || this.delivery.delivery_end_time)
        classes.push('delivery-event-heading-time');

      return classes.join(' ');
    },
    isPickup() {
      return this.delivery.delivery_event_type_id === 2;
    },
    isCollect() {
      return this.delivery.delivery_event_type_id === 8;
    },
    isDelivery() {
      return this.delivery.delivery_event_type_id === 3 || this.isCollect;
    },
    hasHistory() {
      const noHistory = [1, 2, 4, 5, 7, 12, 13, 15, 16, 18];
      return !noHistory.filter((m) => m === this.delivery.delivery_event_type_id)[0];
    },
    completedState() {
      return [4, 6, 7, 11].includes(this.delivery.delivery_event_type_id); // Delivered, Rejected, Canceled, Failed
    },
    isRemovable() {
      const mandatories = [1, 2, 5, 7, 12, 13, 15, 16];
      return !mandatories.filter((m) => m === this.delivery.delivery_event_type_id)[0];
    },
    etaTiming() {
      const { eta, delivery_date } = this.delivery;
      console.log(eta, delivery_date);
      return 1;
    },
    Permission() {
      return Permissions.Scheduling;
    },
    optionsDropdown() {
      const eventStatusId = this.delivery.delivery_event_status_id;
      const sortByIndex = sortBy(prop('priority'));

      // prettier-ignore
      let options = [
        { value: this.$t('schedule.editParticipantInfo'), event: 'editParticipant', priority: 2, showOn: [1] },
        { value: this.$t('schedule.deliveryHistory'), event: 'changeDeliveryHistory', priority: 1 },
        { value: this.$t('copayment.manage'), event: 'editCopayment', priority: 1 ,
          showOn: [1],
        },
        { value: this.$t('global.unassign'), event: 'unassignDelivery', priority: 1 }
      ];
      const filteredOptions = options.filter(({ hideOn, showOn, showOnDisabled }) => {
        if (this.disabled) {
          if (showOnDisabled) return true;
          else return false;
        } else {
          if (showOn?.length || hideOn?.length) {
            if (hideOn && hideOn.some((ho) => ho === eventStatusId)) return false;
            if (showOn) return showOn.some((so) => so === eventStatusId);
            else return true;
          } else return true;
        }
      });

      return sortByIndex(filteredOptions);
    }
  },
  methods: {
    async onAddPickup(position) {
      const delivery = this.delivery;
      const secuence = delivery.secuence + (position === 'above' ? 0 : 1);
      this.$emit('onAddPickup', { delivery, secuence, position });
    },
    async onRemovePickup() {
      this.$emit('onRemovePickup', this.delivery.id);
    },
    async onUndoAssign() {
      const { id, delivery_manifest_id, order_number } = this.delivery;
      this.loading.undoAssign = true;
      try {
        console.log(this.delivery);
        await this.Api.delete(`delivery_manifest/${delivery_manifest_id}/delivery_events/${id}`);
        this.$toast(
          'success',
          this.$tc('schedule.messages.unassigned', 1, [`#${order_number}`]),
          5000
        );
        this.$emit('removed', this.delivery);
      } catch (error) {
        this.$emit('openUnassignedModal', this.delivery);
      }
      this.loading.undoAssign = false;
    },
    emitDropdownEvent({ event }) {
      switch (event) {
        case 'editCopayment':
        case 'editParticipant':
          this.$emit(event, event, this.delivery);
          break;
        case 'changeDeliveryHistory':
          this.onShowDeliveryHistory();
          break;
        case 'unassignDelivery':
          this.onUndoAssign();
          break;
        default:
          return null;
      }
    },
    formatTime(dateTime) {
      if (!dateTime) return '-';
      const utcDate = this.$moment.utc(dateTime).toDate();
      return this.$moment(utcDate).format('hh:mm A');
    },
    onShowDeliveryHistory() {
      this.$store.commit('dispatch/deliveryHistory', this.delivery);
    }
  },
  watch: {
    loadingPickup(value) {
      if (value && this.delivery.id === value.id) {
        this.loading.pickup = value.position;
      } else {
        this.loading.pickup = null;
      }
    }
  },
  props: {
    delivery: { type: Object, required: true },
    loadingRemovePickup: { type: Number, required: false },
    disabled: { type: Boolean, default: false },
    loadingPickup: { type: Object, default: () => {} },
    showAddress: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.delivery-event
  width: 100%
  margin-bottom: 4px
  position: relative
  .removePickup
    margin-right: -.6rem
    margin-left: .2rem
    padding-left: .2rem
    border-left: 1px dashed $gray-100
    &.isLoading
      opacity: 1!important
  .pickup-loading
    height: 31px
  .cdp-indicator
    position: absolute
    background: #3f51b5
    width: 7px
    top: 10px
    bottom: 10px
    left: 2px
    border-radius: $br-sm
  .delivery-event-heading
    background: $main-background
    transition: $transition-dark-mode
    padding-right: 5px
    border-radius: 4px
    .add-above, .add-bellow,.removePickup
      transition: opacity .2s ease
      opacity: 0
    &:hover
      .removePickup,.add-above, .add-bellow
        opacity: 1
    ::v-deep
      .is-medium:nth-child(2)
        width: 24px
        height: 24px
        margin-right: 2px
        .priority-icon:before
          font-size: 48px
    .eta
      font-weight: bold
    .small
      font-size: $f-xxs
      .eta-timing
        font-weight: bold
      ::v-deep
        i:before
          font-size: $f-md
    &.is-open
      border-radius: 4px 4px 0px 0px
    .title-container
      align-items: center
      .priority-dot
        margin-right: 2.5px
      ::v-deep
        .refrigerated-icon
          margin-right: 2.5px
    .bold
      font-weight: bold
    .title-type
      line-height: $f-lg
      font-size: 14px
      text-transform: uppercase
      font-weight: 500
    .icons-deliverys
      ::v-deep
        .simple-delivery .icon
          i:before
            transform: translate(-50%, -17px) !important
        .simple-delivery__collect .icon,  .simple-delivery__refrigerated .icon
          i:before
            transform: translate(-50%, -12px) !important
    .title-address,
    .title-cdp
      line-height: 16px
      font-weight: bold
      font-size: $f-xs
      color: $gray-800
      .upDownBoxIcon
        min-width: 24px
        width: 24px
      ::v-deep
        .icon-18
          height: 18px
          &::before
            font-size: 18px
    .title-cdp
      padding: 0
      border-radius: $br-sm
      ::v-deep
        .icon-sm
          color: $secondary
    ::v-deep
      .CustomIcon
        margin-right: 0
      .icon-mixed
        width: 30px!important
        height: 30px!important
      .priority-icon
        margin: 0
        color: green
  .delivery-event-heading-time
    background: $yellow-100-alert
    color: $yellow-900
  .order-number
    margin-left: auto
    ::v-deep
      .link
        margin: 0
  .options-item
    width: 20px
  .delivery-event-collapse
    background: $main-background
    transition: $transition-dark-mode
    border-radius: 0px 0px 4px 4px
    .delivery-event-body
      padding: 0 20px 20px 20px
      .alert-advice
        background: $yellow-100-alert !important
        margin-bottom: 0.5rem
        align-content: center
        background-color: $yellow-900
        border-left: 4px solid $yellow-900
        border-radius: 0px 4px 4px 0px
        color: $yellow-900
        display: flex
        font-size: 16px
        font-weight: 400
        max-width: 267px
        padding: 0.563rem
        .icon
          margin-right: 0.25rem
          display: flex
          align-content: center
  .phone-info
    .land-icon
      color: $blue-600
    .mobile-icon
      color: $green-600
</style>
<style lang="sass">
.dark
  .delivery-event
    .removePickup
      border-color: $gray-700
  .delivery-event-heading
    background: $dark-500!important
    .title-address
      color: $main-background !important
  .delivery-event-collapse
    background: $gray-800!important
  .delivery-event-heading-time
    background: #766D27 !important
    color: #F8F7FD !important
  .phone-info
    .land-icon
      color: $blue-400 !important
    .mobile-icon
      color: $green-500 !important
</style>
