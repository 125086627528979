<template>
  <Card>
    <NewLunchBreak
      :isOpen.sync="lunchBreak.showModal"
      @saved="$refs.Manifest.getDeliveryEvents()"
    />
    <BypassModal
      :isOpen.sync="modals.isBypass"
      :employeeId="employeeId"
      @success="handleSuccessByPass"
    />
    <div class="main-container">
      <Filters
        class="filters-xl"
        v-if="this.width < 1200"
        :disabled="loading"
        :isLargeScreen="true"
        @scheduling="$refs.Table.getData()"
        @input="(value) => (params = value)"
        @savedLunchBreak="$refs.Manifest.getDeliveryEvents()"
        @recalculateManifestUpdate="$refs.Manifest.getManifests()"
      />
      <div class="flex">
        <template v-if="this.width < 1200">
          <b-tabs class="is-visible-xl" v-model="activeTab">
            <b-tab-item :label="$t('schedule.mainTableTitle')">
              <MainTable ref="Table" :loading.sync="loading" :params="params" />
            </b-tab-item>
            <b-tab-item :label="$t('schedule.manifest.label')">
              <Manifest
                ref="Manifest"
                @removed="$refs.Table.getData()"
                @onCDPsInManifest="(value) => (CDPsInManifest = value)"
                :CDPs="CDPs"
                :CDPsFilter="CDPsFilter"
              />
            </b-tab-item>
          </b-tabs>
        </template>
        <template v-else>
          <div id="schedule-table-side" class="is-hidden-xl">
            <Filters
              :disabled="loading"
              @scheduling="$refs.Table.getData()"
              @input="(value) => (params = value)"
              @savedLunchBreak="$refs.Manifest.getDeliveryEvents()"
              @recalculateManifestUpdate="$refs.Manifest.getManifests()"
            />
            <MainTable ref="Table" :loading.sync="loading" :params="params" />
          </div>
          <div id="schedule-manifest-side" class="is-hidden-xl">
            <div class="flex f-jsb w-100">
              <!-- <Button
                type="is-primary"
                :label="$t('schedule.addLunch')"
                icon-left="silverware-fork-knife"
                disabled
                @click="lunchBreak.showModal = true"
                :permission="Permission.assign"
              /> -->
              <RecalculateManifestRoute
                :skeletonLoading="loading"
                @update="$refs.Manifest.getManifests()"
                class="recalculate-manifest"
                outlined
              />
              <Button
                :disabled="disabledBypass"
                :permission="PermissionBypass.bypass"
                :skeletonLoading="loading"
                :tooltip="$t('dispatch.bypassToolTip')"
                @click="isBypassOpen"
                icon-right="debug-step-over"
                outlined
                type="is-primary"
              />
              <CDPSelector
                :skeletonLoading="loading"
                @loaded="setCDPs"
                v-model="CDPsFilter"
                :filterIds="CDPsInManifest"
              />
            </div>
            <Manifest
              ref="Manifest"
              @removed="$refs.Table.getData()"
              @onCDPsInManifest="(value) => (CDPsInManifest = value)"
              :CDPs="CDPs"
              :CDPsFilter="CDPsFilter"
            />
          </div>
        </template>
      </div>
    </div>
  </Card>
</template>

<script>
import { CDPSelector } from '..';
import Filters from './Filters.vue';
import MainTable from './MainTable.vue';
import Manifest from './Manifest.vue';
import NewLunchBreak from './NewLunchBreak.vue';
import { Card, RecalculateManifestRoute, Button } from '@/components';
import { Permissions } from '@/utils/Secure';
import { BypassModal } from '@/views/Fragments';

export default {
  components: {
    Button,
    BypassModal,
    Card,
    Filters,
    MainTable,
    Manifest,
    NewLunchBreak,
    RecalculateManifestRoute,
    CDPSelector
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.unsubscribe = this.$store.subscribe(
      ({ type }, { schedule: { currentManifest, lunchBreak } }) => {
        if (type === 'schedule/currentManifest') this.manifest = currentManifest;
        if (type == 'schedule/lunchBreak') {
          if (this.manifest.delivery_manifest_status_id !== 4)
            this.lunchBreak.disabledButton = !!lunchBreak;
        }
      }
    );
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
    this.unsubscribe();
  },
  data() {
    return {
      activeTab: 0,
      loading: false,
      lunchBreak: { showModal: false, disabledButton: true },
      manifest: null,
      params: {},
      unsubscribe: null,
      width: window.innerWidth,
      employeeId: null,
      CDPs: [],
      CDPsFilter: [],
      CDPsInManifest: [],
      modals: {
        isBypass: false
      }
    };
  },
  computed: {
    disabledBypass() {
      return this.manifest?.manifest_status_name === 'Done';
    },
    Permission() {
      return Permissions.Scheduling;
    },
    PermissionBypass() {
      return Permissions.Employees;
    }
  },
  methods: {
    handleSuccessByPass() {
      this.$refs.Manifest.getManifests();
    },
    onResize() {
      this.width = window.innerWidth;
    },
    setCDPs(CDPs) {
      this.CDPs = CDPs;
    },
    isBypassOpen() {
      this.modals.isBypass = true;
      this.employeeId = this.manifest.employee_id;
    }
  },
  watch: {
    manifest(manifest) {
      this.lunchBreak.disabledButton = manifest.delivery_manifest_status_id == 4;
    }
  },
  props: {}
};
</script>

<style lang="sass" scoped>
.card
  height: calc(100% - 100px)
  .main-container
    #schedule-table-side
      width: 100%
      margin-right: 15px
    #schedule-manifest-side
      margin-top: 27px
      .dropdown
        width: 100%
        ::v-deep
          .dropdown-trigger, .dropdown-menu
            width: 100%
      .recalculate-manifest
        width: 100%
        margin-right: 1rem !important
        ::v-deep
          .dropdown-trigger
            width: 100% !important
            .button
              margin-left: 0 !important
    ::v-deep
      .card-content,
      .card-content > .flex
        height: 100%
      .b-tabs .tabs a
        font-weight: 700
      .filters-xl
        z-index: auto
    ::v-deep
      .icon
        z-index: auto
    @media only screen and (max-width: $bp-xl)
      .is-visible-xl
        width: 100%
</style>
<style lang="sass" scoped>
.dark
  @media only screen and (max-width: $bp-xl)
    .card .main-container
      ::v-deep
        .b-tabs a
          color: $gray-600
          &:hover
            border-bottom: none
        .is-active a
          color: $blue-300
          border-bottom-color: $blue-300
    .card .main-container
      ::v-deep
        .icon
          .mdi-clipboard-arrow-left,
          .mdi-arrow-right-bold-box,
          .mdi-dots-horizontal
            color: $blue-300
</style>
