<template>
  <div id="manifest">
    <ModalCopayment
      :isOpen.sync="modals.editCopayment"
      :delivery="deliveryForModals"
      @saved="handleSavedCopayment"
    />
    <ModalEditParticipant
      :isOpen.sync="modals.editParticipant"
      :delivery="deliveryForModals"
      @updateEvents="getDeliveryEvents"
    />
    <UnasignDeliveryModal
      :delivery="deliveryUnassign"
      :isOpen.sync="modals.isUnassign"
      @executeDesignation="onRemoved"
      onlyRequiereConf
    />

    <div id="manifest-header" class="flex f-jsb f-ai title-container f-wrap">
      <div v-if="loading.manifest" class="w-100">
        <p class="title"><b-skeleton width="100%" height="30" /></p>
        <div class="flex f-jsb mt-2">
          <div><b-skeleton width="110" height="25" /></div>
          <div><b-skeleton width="150" /></div>
        </div>
      </div>
      <template v-else-if="manifest">
        <div class="w-100">
          <p class="title">{{ manifest.employee_name }}</p>
        </div>
        <p v-if="workingHours > 8" class="help is-danger has-text-weight-bold has-text-right">
          {{ $t('schedule.manifest.exceedsWorkingHours') }}
        </p>
        <div class="flex f-ai f-jsb w-100" :class="isbTab ? 'p-2 pb-0' : ''">
          <ManifestStatusSignUI :id="manifest.delivery_manifest_status_id" class="mr-1" />
          <div class="eDistance text has-text-right ml-4">
            {{ $t('schedule.manifest.estimatedDistance') }}: {{ distance }} mi
          </div>
        </div>
      </template>
    </div>
    <div id="manifest-container">
      <div v-if="loading.manifest" id="manifest-loading">
        <b-skeleton height="100%" />
      </div>
      <template v-else>
        <div v-if="loading.deliveryEvents">
          <b-skeleton v-for="i in 10" :key="i" height="60" />
        </div>
        <template v-else>
          <Empty v-if="!filteredDraggableDeliveries.length" :text="$t('global.emptyByFilter')" />
          <DraggableList
            v-show="filteredDraggableDeliveries.length"
            :disabled="!hasPermission || isReadOnly"
            :value="filteredDraggableDeliveries"
            :disabledItem="{ field: 'delivery_event_type_id', value: [4, 5] }"
            :confirmation="dragConfirmation"
          >
            <template #default="item">
              <DeliveryEvent
                :delivery="item"
                :disabled="isReadOnly"
                :key="item.id"
                :loadingPickup="loading.pickup"
                :loadingRemovePickup="loading.removePickup"
                @onRemovePickup="onRemovePickup"
                @onAddPickup="onAddPickup"
                @editCopayment="onOpenModal"
                @editParticipant="onOpenModal"
                @openUnassignedModal="onOpenUnassignModal"
                @removed="onRemoved"
                showAddress
              />
            </template>
          </DraggableList>
          <Empty v-if="!deliveryEvents.length" :text="$t('manifest.deliveryEventLess')" />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import DeliveryEvent from './DeliveryEvent.vue';
import { Empty, DraggableList, ManifestStatusSignUI, ModalEditParticipant } from '@/components';
import { UnasignDeliveryModal } from '@/components/Dispatch/Modals/';
import { toast, confirm } from '@/utils/dialog';
import { MetersToMiles } from '@/utils/Metrics';
import { Permissions } from '@/utils/Secure';
import { handlerAddPickup, handlerRemovePickup } from '../../Services/Pickup';
import { filter, isEmpty, uniq } from 'ramda';
import { ModalCopayment } from '..';
import { delayer } from '../../utils/Helpers';

export default {
  components: {
    DeliveryEvent,
    DraggableList,
    Empty,
    ManifestStatusSignUI,
    ModalEditParticipant,
    UnasignDeliveryModal,
    ModalCopayment
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { schedule: { filters } }) => {
      if (type == 'schedule/filters') {
        this.runId = filters.driver_run_id;
        this.date = filters.date;
      }
      if (type == 'schedule/assignedDelivery') {
        this.getDeliveryEvents();
      }
    });
    this.autoUpdate();
  },
  destroyed() {
    this.unsubscribe();
  },
  data() {
    return {
      autoUpdateInterval: null,
      cancelToken: null,
      date: null,
      deliveryForModals: {},
      deliveryEvents: [],
      draggableDeliveries: [],
      loading: {
        manifest: true,
        deliveryEvents: false,
        undoAssign: false,
        pickup: null,
        removePickup: null
      },
      manifests: [],
      runId: null,
      unsubscribe: null,
      modals: {
        editParticipant: false,
        editCopayment: false,
        isUnassign: false
      },
      deliveryUnassign: {}
    };
  },
  computed: {
    manifest() {
      return this.manifests.filter((manifest) => {
        return manifest.driver_run_id === this.runId;
      })[0];
    },
    CDPsInManifest() {
      const validId = (n) => !!n;
      return filter(validId, uniq(this.draggableDeliveries.map((DD) => DD.close_door_pharmacy_id)));
    },
    distance() {
      return this.manifest ? MetersToMiles(this.manifest.estimated_distance) : 0;
    },
    isReadOnly() {
      return this.manifest ? [3, 4].includes(this.manifest.delivery_manifest_status_id) : false; // Manifest Running or Done
    },
    workingHours() {
      return this.manifest ? this.manifest.estimated_time / 60 / 60 : 0;
    },
    filteredDraggableDeliveries() {
      let deliveries = this.draggableDeliveries;
      if (!isEmpty(this.CDPsFilter)) {
        deliveries = deliveries.filter((delivery) => {
          return this.CDPsFilter.find((CDPid) => delivery.close_door_pharmacy_id === CDPid);
        });
      }
      return deliveries;
    },
    hasPermission() {
      return this.Secure.permissionValidator(Permissions.Dispatch.update);
    }
  },
  methods: {
    async autoUpdate() {
      await delayer(60000);
      const isLoading = this.loading.deliveryEvents || this.loading.manifest;
      const updateAllowed = this.manifest?.id && !isLoading;
      try {
        if (updateAllowed) await this.getDeliveryEvents(!this.draggableDeliveries.length);
      } catch (error) {
        console.log(error);
      }
      this.autoUpdate();
    },
    onOpenUnassignModal(data) {
      this.deliveryUnassign = data;
      this.modals.isUnassign = true;
    },
    handleSavedCopayment(copayment_amount) {
      const delivery = this.draggableDeliveries.find(({ id }) => id === this.deliveryForModals.id);
      if (delivery) delivery.copayment_amount = copayment_amount;
    },
    onOpenModal(modalName, delivery) {
      this.deliveryForModals = delivery;
      this.modals[modalName] = true;
    },
    cancelRequest() {
      if (this.cancelToken) this.cancelToken.cancel('Avoid multiple');
    },
    async createManifest(date) {
      const currentDate = this.$moment(date);
      const minDate = this.$moment().hours(0).minutes(0).seconds(0).milliseconds(0);
      if (currentDate >= minDate) {
        try {
          await this.Api.post(`delivery_manifest`, { date });
        } catch (error) {
          console.error(error);
          this.loading.manifest = false;
        }
        this.getManifests(date);
      }
    },
    isLunchBreak({ nextItem, prevItem, item }) {
      return (
        item?.delivery_event_type_id === 4 ||
        item?.delivery_event_type_id === 5 ||
        (nextItem?.delivery_event_type_id === 5 && prevItem?.delivery_event_type_id === 4)
      );
    },
    async dragConfirmation({ newIndex, item, ...rest }) {
      if (this.isLunchBreak({ item, ...rest })) return false;
      const result = await this.updateDeliverySecuence(item.id, newIndex + 2);
      if (result) {
        toast('success', this.$t('messages.updated'));
        this.getDeliveryEvents();
      }
      return false;
    },
    async getDeliveryEvents(showLoader = true) {
      this.cancelRequest();
      this.cancelToken = this.Api.cancelToken;
      if (this.manifest?.id) {
        if (showLoader) this.loading.deliveryEvents = true;
        try {
          const { data } = await this.Api.get(`delivery_manifest/${this.manifest.id}`, {
            cancelToken: this.cancelToken.token
          });
          this.deliveryEvents = data.events;
          await this.syncEventsToCDP();
        } catch (Err) {
          throw new Error(Err);
        }
        this.loading.deliveryEvents = false;
      }
    },
    CDPsById(id) {
      return this.CDPs.find((CDP) => CDP.id === id);
    },
    async syncEventsToCDP() {
      const hasCDPs = !isEmpty(this.CDPs);
      const hasEvents = !isEmpty(this.deliveryEvents);

      if (hasCDPs && hasEvents) {
        this.deliveryEvents.map((event) => {
          if (event.close_door_pharmacy_id) {
            event.color = this.CDPsById(event.close_door_pharmacy_id)?.color;
          }
        });
      }
    },
    async onRemovePickup(deliveryId) {
      this.loading.removePickup = deliveryId;
      try {
        const success = await handlerRemovePickup({ manifestId: this.manifest.id, deliveryId });

        if (success) await this.getDeliveryEvents(false);
      } catch (error) {
        console.log(error);
      }
      this.loading.removePickup = null;
    },
    async onAddPickup({ delivery, secuence, position }) {
      this.loading.pickup = { id: delivery.id, position };
      const success = await handlerAddPickup({ ...delivery, secuence });
      if (success) await this.getDeliveryEvents(false);
      this.loading.pickup = null;
    },
    async getManifests(date = this.date) {
      this.loading.manifest = true;
      try {
        const { data } = await this.Api.get(`delivery_manifest?date=${date}&per_page=9999`);
        this.manifests = data || [];
        this.$emit('input', this.manifest?.id);
        this.$store.commit('schedule/manifests', data);
      } catch (error) {
        console.error(error);
      }
      this.loading.manifest = false;
    },
    async updateDeliverySecuence(eventId, secuence, isOverride) {
      try {
        await this.Api.put(
          `delivery_manifest/${this.manifest.id}/delivery_events${
            isOverride ? '_override' : ''
          }/${eventId}`,
          {
            secuence
          }
        );
        return true;
      } catch (error) {
        if (error?.data?.status_code === 'OVERTIME_WORK') {
          return await new Promise((resolve) => {
            confirm({
              title: this.$t('confirms.continue'),
              message: this.$t('schedule.confirm.overtime'),
              cancelText: this.$t('confirm.no'),
              confirmText: this.$t('confirm.yes'),
              onCancel: () => resolve(false),
              onConfirm: () => resolve(this.updateDeliverySecuence(eventId, secuence, true))
            });
          });
        } else return false;
      }
    },
    onRemoved({ delivery_event_type_id }) {
      if (delivery_event_type_id == 3) this.$emit('removed', true);
      if (delivery_event_type_id == 4) this.$store.commit('schedule/lunchBreak', false);
      this.getDeliveryEvents();
    }
  },
  watch: {
    async manifest(data) {
      try {
        this.deliveryEvents = [];
        this.$store.commit('schedule/currentManifest', data);
        await this.getDeliveryEvents();
        this.$store.commit('schedule/currentManifest', data);
        // const hasLunchEvent = !!this.deliveryEvents.filter(
        //   (event) => event.delivery_event_type_id == 4
        // )[0];
        // this.$store.commit('schedule/lunchBreak', hasLunchEvent);
      } catch (err) {
        console.log(err);
      }
    },
    deliveryEvents(events) {
      this.draggableDeliveries = events.filter((e) => {
        const type = e.delivery_event_type_id;
        const mandatory = e.event_type_is_mandatory;
        return (type >= 2 && type <= 5) || (type === 8 && mandatory === 0);
      });
    },
    date(value) {
      if (value) this.createManifest(value);
    },
    CDPsFilter() {
      this.syncEventsToCDP();
    },
    CDPsInManifest(value) {
      this.$emit('onCDPsInManifest', value);
    }
  },
  props: {
    isbTab: { type: Boolean, default: false },
    CDPs: { type: Array, default: () => {} },
    CDPsFilter: { type: Array, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
#manifest
  transition: $transition-dark-mode
  width: 370px
  height: calc(100vh - 185px) !important
  display: flex
  flex-flow: column
  border-radius: $br-md
  padding-top: 5px
  .f-sm
    font-size: $f-sm
  .draggable-item:last-child
    .delivery-event
      margin: 0
  .title-container
    margin: 10px 0px
    .title
      margin: auto 0
      font-size: $f-xl
      font-weight: bold
      height: 30px
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
    .text
      font-size: $f-xs
    .help.is-danger
      margin: 0
  #manifest-container
    height: 100%
    overflow: auto
    border: 1px solid $gray-50
    border-radius: $br-sm
    .draggable-list
      height: 100%
    #noDeliveries
      font-weight: bold
      font-size: $f-xxl
      color: $dark-300
    #manifest-loading
      height: 100%
      .b-skeleton
        height: 100%
@media only screen and (max-width: $bp-xl)
  #manifest
    padding: 0px
    #manifest-header
      min-height: auto
</style>
